/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* --------------------------------------------------------------------------------
 * Basic settings.
 * -------------------------------------------------------------------------------- */
html {
  font-size: 62.5%;
}

body {
  color: #323232;
  background-color: #eceff1;
  font-size: 1.2rem;
  font-family: "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
}

.body-wrapper {
  min-height: 95vh;
  /* ↑ Fallback of calc() for unsupported browsers */
  min-height: calc(100vh - 50px);
  position: relative;
  padding-bottom: 30px;
  box-sizing: border-box;
}

// ステータスカラー
.global-status-signal {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 14px;
  text-align: center;
  line-height: 14px;
  margin-right: 2px;
}
.global-status-signal-1 {
  @extend .global-status-signal;
  color: #305496;
  background-color: #305496;
}
.global-status-signal-2 {
  @extend .global-status-signal;
  color: #548235;
  background-color: #548235;
}
.global-status-signal-3 {
  @extend .global-status-signal;
  color: #ffff00;
  background-color: #ffff00;
}
.global-status-signal-10 {
  @extend .global-status-signal;
  color: #ff9900;
  background-color: #ff9900;
}
.global-status-signal-11 {
  @extend .global-status-signal;
  color: #ff0000;
  background-color: #ff0000;
}
.global-status-signal-20 {
  @extend .global-status-signal;
  color: #a50021;
  background-color: #a50021;
}
.global-status-signal-99 {
  @extend .global-status-signal;
  color: #757575;
  background-color: #757575;
}

.global-status-temperature-signal-1 {
  @extend .global-status-signal;
  color: #B40068;
  background-color: #B40068;
}
.global-status-temperature-signal-2 {
  @extend .global-status-signal;
  color: #FF2800;
  background-color: #FF2800;
}
.global-status-temperature-signal-3 {
  @extend .global-status-signal;
  color: #FF9900;
  background-color: #FF9900;
}
.global-status-temperature-signal-4 {
  @extend .global-status-signal;
  color: #FAF500;
  background-color: #FAF500;
}
.global-status-temperature-signal-5 {
  @extend .global-status-signal;
  color: #FFFF96;
  background-color: #FFFF96;
}
.global-status-temperature-signal-6 {
  @extend .global-status-signal;
  color: #FFFFF0;
  background-color: #FFFFF0;
  border: solid 1px #777777;
}
.global-status-temperature-signal-7 {
  @extend .global-status-signal;
  color: #B9EBFF;
  background-color: #B9EBFF;
}
.global-status-temperature-signal-8 {
  @extend .global-status-signal;
  color: #0096FF;
  background-color: #0096FF;
}
.global-status-temperature-signal-9 {
  @extend .global-status-signal;
  color: #0041FF;
  background-color: #0041FF;
}
.global-status-temperature-signal-10 {
  @extend .global-status-signal;
  color: #002080;
  background-color: #002080;
}
.global-status-temperature-signal-99 {
  @extend .global-status-signal;
  color: #757575;
  background-color: #757575;
}

.global-status-wind-signal-1 {
  @extend .global-status-signal;
  color: #003366;
  background-color: #003366;
}
.global-status-wind-signal-2 {
  @extend .global-status-signal;
  color: #339933;
  background-color: #339933;
}
.global-status-wind-signal-3 {
  @extend .global-status-signal;
  color: #ccff33;
  background-color: #ccff33;
}
.global-status-wind-signal-10 {
  @extend .global-status-signal;
  color: #ffdb18;
  background-color: #ffdb18;
}
.global-status-wind-signal-20 {
  @extend .global-status-signal;
  color: #ff0000;
  background-color: #ff0000;
}
.global-status-wind-signal-99 {
  @extend .global-status-signal;
  color: #757575;
  background-color: #757575;
}

/* --------------------------------------------------------------------------------
 * footer.
 * -------------------------------------------------------------------------------- */
.footer-wrapper {
  height: 20px;
  width: 100%;
  text-align: center;
  padding-top: 10px;
  position: absolute;
  bottom: 0;
  background-color: #eceff1;
}

/* --------------------------------------------------------------------------------
 * Basic settings of the listing page.
 * -------------------------------------------------------------------------------- */
.listing-page-wrapper .listing-title-wrapper {
  margin-top: 50px;
  height: 35px;
  background-color: #78bbe6;
  padding: 0 10px;
}

.listing-page-wrapper h1,
.listing-page-wrapper th {
  font-weight: normal;
}

.listing-page-wrapper h1 {
  line-height: 35px;
  color: #ffffff;
  font-size: 1.4rem;
}

.search-criteria-wrapper {
  background-color: #ffffff;
}

.search-criteria {
  height: 50px;
  width: 95%;
  padding: 15px 10px 5px;
  margin: 0 0 0;
  background-color: #ffffff;
  display: table;
  table-layout: fixed;
}

.search-criteria:hover {
  opacity: 0.8;
}

.search-condition-wrapper {
  display: table-cell;
}

.search-criteria .form-field {
  margin: 0 auto 0;
  width: 200px;
}

.search-criteria .date-from .form-field,
.search-criteria .date-to .form-field {
  width: 100px;
}

.search-functions-wrapper {
  display: table;
  height: 30px;
  width: 100%;
  margin: 0 auto;
  padding: 5px 0px;
  background-color: #eceff1;
}

.search-functions-paginator {
  display: table-cell;
  width: 365px;
}

.search-functions-buttons {
  display: table-cell;
  text-align: right;
}

.search-functions-wrapper .mat-paginator-container {
  justify-content: left;
  padding-left: 8px;
  min-height: 50px;
  min-width: 400px;
  background-color: #eceff1;
}

.search-functions-wrapper .flat-button {
  margin-right: 10px;
  width: 200px;
  border-radius: 2px;
  background-color: #78bbe6;
  color: #ffffff;
  letter-spacing: 1px;
  font-size: 1.2rem;
}

.search-functions-wrapper .flat-button:hover {
  opacity: 0.8;
}

.search-functions-wrapper .flat-button.active {
  background-color: #f99f48;
  color: #ffffff;
}

.search-results-wrapper {
  width: 100%;
  text-align: center;
}

.search-results-wrapper:hover {
  opacity: 0.8;
}

.search-results-wrapper .search-results {
  table-layout: fixed;
  border-collapse: separate;
  width: 100%;
}

.search-results-wrapper thead,
.search-results-wrapper tbody {
  display: block;
}

.search-results-wrapper tbody {
  overflow-y: scroll;
  height: 55vh;
  /* ↑ Fallback of calc() for unsupported browsers */
  height: calc(100vh - 310px);
}

.search-results-wrapper .search-results table,
.search-results-wrapper .search-results th,
.search-results-wrapper .search-results td {
  height: 40px;
  vertical-align: middle;
  text-align: center;
}

.search-results-wrapper .search-results th {
  color: #323232;
  background-color: #d5eeff;
  border-bottom: 1px solid #f2f2f2;
  text-align: center;
  min-width: 100px;
}

.search-results-wrapper .search-results tr {
  background-color: #ffffff;
}

.search-results-wrapper .search-results td {
  border-bottom: 1px solid #f2f2f2;
  text-align: center;
  min-width: 100px;
}

.search-results-wrapper .search-results a {
  color: #323232;
}

.search-results-wrapper .search-results tr:hover {
  background-color: rgb(213, 238, 255, 0.1);
  cursor: pointer;
}

/* --------------------------------------------------------------------------------
 * Basic settings of the registering page.
 * -------------------------------------------------------------------------------- */
.registering-page-wrapper {
  margin: 50px auto 10px;
  padding-bottom: 25px;
}

.registering-title-wrapper {
  height: 35px;
  background-color: #78bbe6;
  padding: 0 10px;
}

.registering-title {
  width: 820px;
  margin: 0 auto;
}

.registering-title h1 {
  line-height: 35px;
  font-weight: normal;
  color: #ffffff;
  font-size: 1.4rem;
}

.registering-criteria-wrapper {
  height: 520px;
  background-color: #ffffff;
}

.registering-criteria-wrapper .button:hover {
  opacity: 0.8;
}

/* .registering-criteria-wrapper .flat-button:hover {
  opacity: 0.8;
} */

.registering-criteria,
.registering-functions {
  width: 770px;
  margin: 0 auto;
  padding: 0 0 10px;
}

.registering-criteria {
  display: table;
}

.registering-functions {
  padding-top: 20px;
}

.registering-area-wrapper {
  vertical-align: top;
  display: table-cell;
}

.registering-area-wrapper.left {
  padding-top: 10px;
  width: 500px;
}

.registering-area-wrapper.right {
  width: 270px;
  text-align: right;
}

.registering-area-wrapper img {
  max-width: 200px;
}

.registering-area-wrapper p {
  margin-left: 70px;
  text-align: left;
}

.registering-condition-wrapper {
  margin: 10px auto 0;
}

.registering-criteria-wrapper input[type="text"] {
  margin-top: 30px;
  margin-bottom: 30px;
  border-width: 0;
  font-size: 1.4rem;
  display: block;
}

/* .registering-criteria-wrapper .form-field {
  width: 20rem;
  font-size: 1.4rem;
}

.registering-criteria-wrapper .form-field.width-wide {
  width: 40rem;
}

.registering-criteria-wrapper .validation-error {
  margin-top: 0.2rem;
} */

.registering-criteria-wrapper p {
  margin-top: 30px;
  font-size: 1.4rem;
  display: block;
}

.registering-functions-wrapper {
  height: 30px;
  text-align: right;
}

.registering-area-wrapper .button,
.registering-functions-wrapper .button {
  display: inline-block;
  padding: 10px 0;
  width: 200px;
  border-radius: 2px;
  background-color: #78bbe6;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  letter-spacing: 1px;
  font-size: 1.2rem;
}

.registering-functions .button {
  padding: 10px 0;
  margin-left: 18px;
}

.registering-functions .button:hover {
  opacity: 0.8;
}

.registering-functions .button.active {
  background-color: #f99f48;
  color: #ffffff;
}

/* .registering-area-wrapper .flat-button,
.registering-functions-wrapper .flat-button {
  width: 200px;
  border-radius: 2px;
  background-color: #78BBE6;
  color: #ffffff;
  letter-spacing: 1px;
  font-size: 1.2rem;
  margin-left: 18px;
}

.registering-functions-wrapper .flat-button:hover {
  opacity: 0.8;
}

.registering-functions-wrapper .flat-button.active {
  background-color: #F99F48;
  color: #ffffff;
}

.registering-functions-wrapper .flat-button:disabled {
  opacity: 0.4;
} */

/* --------------------------------------------------------------------------------
 * Other Material settings.
 * -------------------------------------------------------------------------------- */
.mat-dialog-container {
  background-color: #eceff1;
  box-shadow: none;
  padding: 0 !important;
}

.mat-checkbox.mat-accent.mat-checkbox-checked .mat-checkbox-background {
  background-color: #f99f48;
}

/* --------------------------------------------------------------------------------
 * Smart Phones and tablets settings.
 * -------------------------------------------------------------------------------- */
/* @media screen and (max-width:768px) {
  .listing-page-wrapper h1 {
    text-align: center;
  }

  .search-criteria,
  .search-condition-wrapper {
    display: block;
  }

  .search-criteria {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  .search-criteria .form-field {
    width: 95%;
  }

  .search-criteria .form-field-dates,
  .search-criteria .date-from .form-field,
  .search-criteria .date-to .form-field {
    width: 100%;
  }

  .search-functions-wrapper,
  .search-functions-paginator,
  .search-functions-buttons {
    padding: 0;
    margin: 0;
    display: block;
  }

  .search-functions-wrapper {
    height: auto;
    text-align: center;
  }

  .search-functions-paginator {
    width: 100%;
  }

  .search-functions-wrapper .mat-paginator-container {
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .search-functions-buttons {
    text-align: center;
    background-color: #ffffff;
  }

  .search-functions-wrapper .flat-button {
    height: 40px;
    width: 95%;
    margin: 2px 0;
  }

  .search-results-wrapper .search-results thead {
    display: none;
  }

  .search-results-wrapper .search-results tbody {
    height: auto;
  }

  .search-results-wrapper .search-results tr {
    display: block;
    margin-top: 5px;
    border-bottom: 2px solid #F2F2F2;
  }

  .search-results-wrapper .search-results td {
    display: block;
    height: 2rem;
    border-bottom: 0;
  }

  .search-results-wrapper .search-results td:before {
    content: attr(data-label);
    float: left;
  }

  .registering-page-wrapper {
    padding-bottom: 55px;
    text-align: center;
  }

  .registering-criteria-wrapper {
    height: auto;
    margin-bottom: 30px;
  }

  .registering-criteria-wrapper .registering-criteria,
  .registering-criteria-wrapper .registering-area-wrapper {
    display: block;
  }

  .registering-title,
  .registering-criteria,
  .registering-condition-wrapper,
  .registering-functions,
  .registering-area-wrapper.left,
  .registering-area-wrapper.right,
  .registering-area-wrapper img,
  .registering-criteria-wrapper p {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }

  .registering-criteria-wrapper .registering-condition-wrapper {
    margin: 0;
    padding: 0;
  }

  .registering-criteria-wrapper .form-field,
  .registering-criteria-wrapper .form-field.width-wide {
    width: 95%;
    font-size: 1.2rem;
  }

  .registering-criteria-wrapper p {
    margin-top: 10px;
  }

  .registering-functions-wrapper .registering-functions {
    padding: 0;
  }

  .registering-area-wrapper .flat-button,
  .registering-functions .flat-button {
    height: 40px;
    width: 95%;
    margin: 2px 0;
  }
} */
